
import { Component, Vue } from 'vue-property-decorator';
import { DisplayDutyInfo } from '../../models/Product';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsDuties',
    components: {
    }
})
export default class DetailsDuties extends Vue {

    get duties(): DisplayDutyInfo[] {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.duties ?? [];
        }
        return ProductDetailsModule.PRODUCT?.duties ?? [];
    }

    private getPrice(duty: DisplayDutyInfo)
    {
        if (duty.showpricesincvat)
            return duty.priceincvat

        return duty.priceexvat;
    }    
}
