import { WebCartItem } from '@/models/Cart';

export default class TrackingService {

    public trackAddToCart(item: WebCartItem) {

        if (!item)
            return;
        // facebook pixel tracking
        if (typeof (window as any).fbq === 'function')
        {
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

            (window as any).fbq('track', 'AddToCart', {
                'content_name': item.name,
                'content_category': '',
                'content_ids': [item.productid],
                'content_type': 'product',
                'value': unitpriceincvat.toFixed(2),
                'currency': item.unitprice.currency
            });
        }

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            const linepriceincvat = item.unitprice.price * item.quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).gtag('event', 'add_to_cart', {
                currency: item.unitprice.currency,
                items: [{
                  'item_id': item.productid,
                  'item_name': item.name,
                  coupon: '',
                  discount: unitpricediscountamountincvat.toFixed(2),
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                  currency: item.unitprice.currency,
                  quantity: item.quantity
                }],
                value: linepriceincvat.toFixed(2)
            });
        }
    }
}
