import axios, { AxiosResponse } from 'axios';
import { VariantModel, VariantResult } from '@/models/Variant';

export default class VariantService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/variant`;
    }

    public async getVariants(id: string = ""): Promise<VariantModel[]> {
        if (id.length === 0) return [];
        try {
            const res: AxiosResponse<VariantResult> = await axios.get(`${this.endpoint}/id?id=${id}`);
            return res.data.variants;
        } catch (e) {
            console.log(e);
            return [];
        }
    }
}
