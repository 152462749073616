
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import LoadingModule from '@/store/modules/LoadingModule';
import UserModule from '@/store/modules/UserModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginForm from './LoginForm.vue';

@Component({
    name: 'TopLogin',
    components: {
        LoginForm,
    },
})
export default class TopLogin extends Vue {
    @Prop({ type: String, required: true }) private dashboardUrl: string;
    @Prop({ type: String, required: true }) private signOutUrl: string;
    @Prop({ type: String, required: false, default: '' }) private loggedInAs: string;
    @Prop({ type: String, required: true }) private loginText: string;

    private modals: any = {
        login: false,
    };

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get displayLogin(): boolean {
        return !CbxVariablesModule.VARIABLES.hideLogin;
    }

    get isUserLoading(): boolean {
        return LoadingModule.IS_USER_LOADING;
    }

    get isSignedIn(): boolean {
        return UserModule.IS_SIGNED_IN;
    }

    private handleClick() {
        if (this.isSignedIn) return;
        this.modalShow('login');
    }

    private modalShow(section: string) {
        this.modals[section] = true;
    }

    private modalHide(section: string) {
        this.modals[section] = false;
    }
}
