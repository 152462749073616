const UIkit = window.UIkit;

export default function () {
    const elementSelector = '#homepageSlideshow';
    const node = document.querySelector(elementSelector);
    if (node !== null) {
        let isPlaying = true;
        const toggleSelector = '#homepageSlideshowPause';

        UIkit.util.on(document, 'click', toggleSelector, () => {
            console.log('slider pause')
            if (isPlaying) {
                UIkit.slideshow(elementSelector).stopAutoplay();
            }
            else {
                UIkit.slideshow(elementSelector).startAutoplay();
            }
            isPlaying = !isPlaying;
        });
    }
}