
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator'

import { ProductSearchResultProduct } from '../../models/Product';
import ProductService from '../../services/ProductService';
const ps: ProductService = new ProductService();

@Component({
    name: 'HeaderSearch',
})
export default class HeaderSearch extends Vue {
    @Prop({ type: String, required: true }) private placeholderText: string;
    @Prop({ type: String, required: true }) private actionText: string;
    @Prop({ type: String, required: false, default: 'desktop' }) private viewType: string;

    get isViewType() {
        return this.$screen.breakpoint === this.viewType;
    }

    get isMobile() {
        return this.$screen.breakpoint === 'mobile';
    }    

    private products: ProductSearchResultProduct[] = [];
    private isFetching: boolean = false;
    private keyword: string = '';

    private async search() {
        this.isFetching = true;
        this.products = await ps.search(this.keyword) ;
        this.isFetching = false;
    }
    
    private gotoSearchPage() {
        (window as Window).location.href = `${(window as any).CbxSearchPageUrl}?query=${this.keyword}&orderby=`
    }
    
    private setFocus(index: number) {
        let focusTarget: any;
        if (index < 0 || index > this.products.length-1) {
            focusTarget = this.$refs.searchInput;
        } else {
            focusTarget = (this.$refs[`product-suggestion-${index}`] as any)[0];
        }
        this.$nextTick(() => focusTarget.focus());
    }

    private reset() {
        this.keyword = '';
        this.products = [];
    }

    @Debounce(250)
    private async handleKeywordInput() {
        this.search();
    }

    private handleFocusOut(e: any) {
        if (!this.$el.contains(e.relatedTarget)) {
            this.reset();
        }
    }
}
