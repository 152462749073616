
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'LoadMore',
})
export default class LoadMore extends Vue {
    @Prop({ type: Number, required: false, default: 0 }) private displaying: number;
    @Prop({ type: Number, required: false, default: 0 }) private total: number;
    @Prop({ type: String, required: false, default: '' }) private size: string;

    get indicatorStyle(): string {
        return `width: ${(this.displaying/this.total)*100}%;`
    }
    
}
