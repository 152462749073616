import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import ProductService from '@/services/ProductService';
import { ProductSearchResultProduct } from '@/models/Product';
import GeneralObject from '@/models/GeneralObject';

@Module({
    store,
    dynamic: true,
    name: 'product',
    namespaced: true,
})
class Product extends VuexModule {
    private ps = new ProductService();
    private searchResults: ProductSearchResultProduct[] = [];

    get SEARCH_RESULTS(): ProductSearchResultProduct[] {
        return this.searchResults;
    }

    @Action({ commit: 'SET_SEARCH_RESULTS' })
    public async SEARCH(args: GeneralObject): Promise<ProductSearchResultProduct[]> {
        return await this.ps.search(args.keyword, args.limit);
    }

    @Mutation
    private SET_SEARCH_RESULTS(searchResults: ProductSearchResultProduct[]) {
        this.searchResults = searchResults;
    }
}

export const ProductModule = getModule(Product);
