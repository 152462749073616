
import { RequestNewPasswordRequest, RequestNewPasswordResponse } from '@/models/SignIn';
import UserModule from '@/store/modules/UserModule';
import { Component, Vue } from 'vue-property-decorator';
import Alert from '../utils/Alert.vue';

@Component({
    name: 'LoginResetPasswordForm',
    components: {
        Alert,
    },
})
export default class LoginResetPasswordForm extends Vue {
    private loading: boolean = false;
    private alerts: any = {
        success: false,
        error: false,
    };
    private model: RequestNewPasswordRequest = {
        email: '',
    };

    private mounted() {
        const input = this.$refs.usernameInput;
        if (input) {
            (input as HTMLElement).focus();
        }
    }

    private async submit() {
        if (this.loading) return;
        this.loading = true;
        const response: RequestNewPasswordResponse = await UserModule.REQUEST_NEW_PASSWORD(this.model);
        if (response.emailsent) {
            this.alerts.success = true;
        } else {
            this.alerts.error = true;
        }
        this.loading = false;
    }

    private close() {
        this.$emit('close-modal');
    }
}
