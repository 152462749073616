import axios, { AxiosResponse } from 'axios';
import { WebCartCurrentFreeGift } from '@/models/Cart';

export default class FreeGiftService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/freegift`;
    }

    public getCartCurrentFreeGiftModel(): WebCartCurrentFreeGift {
        return {
            products: [],
            removable: false,
            freegiftpossible: false,
            freegiftobtained: false,
            displayfreegiftlimit: null,
            displayfreegiftdiff: null,
        };
    }

    public async getCurrent(): Promise<WebCartCurrentFreeGift> {
        try {
            const res: AxiosResponse<WebCartCurrentFreeGift> = await axios.get(`${this.endpoint}/getcurrent`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async add(productid: string): Promise<boolean> {
        try {
            await axios.get(`${this.endpoint}/add?productid=${productid}`);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async remove(): Promise<boolean> {
        try {
            await axios.get(`${this.endpoint}/remove`);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
