const UIkit = window.UIkit;

export default function () {
    UIkit.util.on(document, 'change', '.sortorder-form select', function (e) {

        const elem = e.target,
            form = elem.closest('form');

        form.submit();
    });

    UIkit.util.on(document, 'change', '.perpage-form select', function (e) {

        const elem = e.target,
            form = elem.closest('form');

        form.submit();
    });
}