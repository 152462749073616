// @ts-ignore
import Slider from './Slider';
// @ts-ignore
import MainNavigation from './MainNavigation';
// @ts-ignore
import ProductList from './ProductList';
// @ts-ignore
import Login from './Login';
// @ts-ignore
import Utilities from './Utilities';

export default function () {
    Slider();
    MainNavigation();
    ProductList();
    Login();
    Utilities();
}
