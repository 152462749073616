
import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator'
import SearchIcon from '@/components/mobile-header/icons/SearchIcon.vue';
import { ProductModule } from '@/store/modules/ProductModule';
import { ProductSearchResultProduct } from '@/models/Product';

@Component({
    name: 'MobileHeaderSearch',
    components: {
        SearchIcon,
    },
})
export default class MobileHeaderSearch extends Vue {
    private keyword = '';
    private isLoading = false;

    get products(): ProductSearchResultProduct[] {
        return ProductModule.SEARCH_RESULTS;
    }

    private gotoSearchPage() {
        if (this.keyword.length > 0)
            (window as Window).location.href = `${(window as any).CbxSearchPageUrl}?query=${this.keyword}&orderby=`
    }

    @Debounce(250)
    private async handleSearch() {
        this.isLoading = true;
        await ProductModule.SEARCH({
            keyword: this.keyword,
            limit: 5,
        });
        this.isLoading = false;
    }
}
