import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import MobileNavigationService from '@/services/MobileNavigationService';
import GeneralObject from '@/models/GeneralObject';
import { NavigationItemResponse, NavigationItemType } from '@/models/Navigation';

@Module({
    store,
    dynamic: true,
    name: 'mobile-navigation',
    namespaced: true,
})
class MobileNavigation extends VuexModule {
    private mns = new MobileNavigationService();
    private isOpen = false;
    private isLoading = false;
    private navigationData: NavigationItemResponse = {
        children: [],
        parent: null,
    };

    get IS_OPEN(): boolean {
        return this.isOpen;
    }

    get IS_LOADING(): boolean {
        return this.isLoading;
    }

    get NAVIGATION_DATA(): NavigationItemResponse {
        return this.navigationData;
    }

    @Action({ commit: 'SET_NAVIGATION_DATA' })
    public async GET_NAVIGATION_DATA(args: GeneralObject = {}): Promise<NavigationItemResponse> {
        if (args.type === undefined) {
            return await this.mns.getBase();
        } else if (args.type === NavigationItemType.Content) {
            return await this.mns.getContentDataWithParent(args.parent);
        } else if (args.type === NavigationItemType.Category) {
            return await this.mns.getCategoryDataWithParent(args.parent);
        }
    }

    @Action({ commit: 'SET_NAVIGATION'})
    public TOGGLE_NAVIGATION() {
        this.mns.handleBodyOverflow(this.isOpen);
        return this.isOpen;
    }

    @Mutation
    private SET_NAVIGATION_DATA(payload: NavigationItemResponse) {
        this.navigationData['children'] = payload.children;
        this.navigationData['parent'] = payload.parent;
    }

    @Mutation
    public SET_LOADING(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    private SET_NAVIGATION(payload: boolean) {
        this.isOpen = !payload;
    }
}

export const MobileNavigationModule = getModule(MobileNavigation);