import axios from 'axios';
import { ViewEventTypeModel } from '@/models/EventTracking';

export default class EventTrackingService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/event`;
    }

    public async trackView(payload: ViewEventTypeModel): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}/view`, payload);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
