const UIkit = window.UIkit;

export default function () {

    function scrollTo(targetelem) {
        const navbar = document.querySelector(".cbx-navbar-sticky");
        // hack: the sticky adds a padding to body, we meet this by increasing the headerOffset
        const headerOffset = (navbar?.clientHeight ?? 0) * 1.7;
        const offsetPosition = targetelem.getBoundingClientRect().top + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    document.addEventListener('click', function (event) {

        // cbx toggle
        if (UIkit.util.matches(event.target, '[data-cbx-toggle]') || UIkit.util.closest(event.target, '[data-cbx-toggle]') || (event.target?.getAttribute('href')?.startsWith("#") && event.target?.getAttribute('href')?.length > 1))
        {
            const elem = UIkit.util.closest(event.target, '[data-cbx-toggle]') || event.target;
            let toggle = elem.dataset.cbxToggle;

            if (toggle == 'print') {
                window.print();

                event.preventDefault();
                return;
            }

            const target = elem.dataset.target || elem.getAttribute('href'),
                targetelem = document.querySelector(target);

            if (!targetelem) return;

            event.preventDefault();

            // intercept scroll to anchor
            if (!toggle && target.startsWith("#")) toggle = "scroll";

            if (toggle == "scroll") {
                scrollTo(targetelem);
            }

            if (toggle == 'lightbox') {
                UIkit.lightbox(targetelem).show(0);
            }
        }

    }, false);

    let lazyloadImages;
    let lazyloadThrottleTimeout;

    function lazyload() {
        if (lazyloadThrottleTimeout) {
            clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function () {
            const scrollTop = window.pageYOffset;
            lazyloadImages.forEach(function (img) {
                if (img.offsetTop < (window.innerHeight + scrollTop)) {
                    img.src = img.dataset.src;
                    img.classList.remove('lazy');
                }
            });
            if (lazyloadImages.length == 0) {
                document.removeEventListener("scroll", lazyload);
                window.removeEventListener("resize", lazyload);
                window.removeEventListener("orientationChange", lazyload);
            }
        }, 20);
    }

    document.addEventListener("scroll", function () {

        const hash = window.location.hash;

        if (typeof hash !== 'string' || !hash.length) return;

        const targetelem = document.getElementById(hash.substring(1));

        if (!targetelem) return;

        history.replaceState(null, null, ' ');

        scrollTo(targetelem);
    }, false);

    document.addEventListener("DOMContentLoaded", function () {

        const hash = window.location.hash;

        if (typeof hash !== 'string' || !hash.length) return;

        const targetelem = document.getElementById(hash.substring(1));

        if (!targetelem) return;

        history.replaceState(null, null, ' ');

        scrollTo(targetelem);
    }, false);

    document.addEventListener("DOMContentLoaded", function () {

        if ("IntersectionObserver" in window) {
            lazyloadImages = document.querySelectorAll(".lazy");
            const imageObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        const image = entry.target;
                        image.classList.remove("lazy");
                        imageObserver.unobserve(image);
                    }
                });
            });

            lazyloadImages.forEach(function (image) {
                imageObserver.observe(image);
            });
        } else {
            lazyloadImages = document.querySelectorAll(".lazy");

            document.addEventListener("scroll", lazyload);
            window.addEventListener("resize", lazyload);
            window.addEventListener("orientationChange", lazyload);
        }
    });
}