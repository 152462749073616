
import { Component, Vue, Prop } from 'vue-property-decorator';
import CartModule from '../store/modules/CartModule';
import { WebCart, WebCartCurrentDelivery } from '../models/Cart';

@Component({
    name: 'FreeDeliveryNotice',
})
export default class FreeDeliveryNotice extends Vue {

    @Prop({ type: Object, required: true, default: null }) private delivery: WebCartCurrentDelivery;
    @Prop({ type: Boolean, required: false, default: false }) private compact: boolean;

    private get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get cart(): WebCart {
        return CartModule.CART;
    }

    get freeDeliveryPossible(): boolean {
        return this.delivery?.freedeliverypossible ?? false;
    }

    get freeDeliveryObtained(): boolean {
        return this.delivery?.freedeliveryobtained ?? false;
    }
}
