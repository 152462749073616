import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({
    store,
    dynamic: true,
    name: 'loading',
    namespaced: true,
})
class LoadingModule extends VuexModule {

    private isLoading: boolean = false;
    private isUserLoading: boolean = false;

    get IS_LOADING(): boolean {
        return this.isLoading;
    }

    get IS_USER_LOADING(): boolean {
        return this.isUserLoading;
    }

    @Mutation
    public SET_LOADING(args: boolean) {
        this.isLoading = args;
    }

    @Mutation
    public SET_USER_LOADING(args: boolean) {
        this.isUserLoading = args;
    }
}

export default getModule(LoadingModule);