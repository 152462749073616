import UserModule from '@/store/modules/UserModule';
import axios, { AxiosResponse } from 'axios';
import CacheService from '@/services/CacheService';

export default class TranslationService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;
    private ensuredCache: Set<string> = new Set<string>();
    private cacheService = new CacheService();
    private translationCachePrefix = 'translations__';

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/labeltranslation`;
    }

    public async getTranslations(language: string = ''): Promise<any> {
        try {
            const cacheKey = `${this.translationCachePrefix}${language}`;
            const cachedItem: any = await this.cacheService.getCachedItem(cacheKey);
            if (cachedItem) {
                return cachedItem;
            }
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/translations/${language}`);
            const data = res.data;
            this.cacheService.setCachedItem(cacheKey, data);
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    public async clearTranslationCache(language: string = '') {
        const cacheKey = `${this.translationCachePrefix}${language}`;
        await this.cacheService.clearCachedItem(cacheKey);
        return;
    }
    
    public async ensureTranslation(payload: any): Promise<any> {
        if (UserModule.USER_ROLE !== 'Supervisor')
            return;
        const cacheKey = JSON.stringify(payload);
        if (this.ensuredCache.has(cacheKey))
            return;
        this.ensuredCache.add(cacheKey);
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/translations/ensure`, payload);
            return res.data;
        } catch (e) {
            return;
        }
    }
}
